<template>
  <div>
    <template v-if="eForm">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h1>{{ eForm.title }}</h1>
              <h3>{{ eForm.description }}</h3>
            </v-col>
          </v-row>
          <v-divider class="my-5"></v-divider>
          <v-row>
            <v-col cols="12">
              <survey
                :key="keySurvey"
                :survey="survey"
              ></survey>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template v-else>
      <v-row class="justify-center">
        <v-col cols="12">
          {{ $t('eform.notFoundById') }}
        </v-col>
      </v-row>
    </template>
  </div>
</template>
<script>
import * as SurveyVue from 'survey-vue'
import 'survey-vue/modern.min.css'
import * as widgets from 'surveyjs-widgets'
import eFormMixin from '@/mixins/eFormMixin'
import { init as customWidget } from '@/components/surveyjs/customwidget'

const { Survey } = SurveyVue
Survey.cssType = 'default'

// widgets.icheck(SurveyVue);
widgets.select2(SurveyVue)
widgets.inputmask(SurveyVue)
widgets.jquerybarrating(SurveyVue)
widgets.jqueryuidatepicker(SurveyVue)
widgets.nouislider(SurveyVue)
widgets.select2tagbox(SurveyVue)
widgets.sortablejs(SurveyVue)
widgets.ckeditor(SurveyVue)
widgets.autocomplete(SurveyVue)
widgets.bootstrapslider(SurveyVue)
customWidget(SurveyVue)

SurveyVue.Serializer.addProperty('question', 'tag:number')

export default {
  name: 'EFormSurvey',

  components: {
    Survey,
  },

  mixins: [eFormMixin],

  data() {
    const json = {
      title: 'Default',
      showProgressBar: 'top',
      pages: [
        {
          questions: [
            {
              type: 'text',
              name: 'default',
              title: 'This is default survey',
            },
          ],
        },
      ],
    }
    const model = new SurveyVue.Model(json)

    return {
      eForm: null,
      eFormSurveyId: null,
      survey: model,
      keySurvey: 0,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },

  async mounted() {
    const { id } = this.$route.params

    if (id) {
      const self = this

      await this.connectEFormSurvey()
      await this.getEFormById({ id })

      this.eForm.survey.showProgressBar = 'top'
      this.survey = new SurveyVue.Model(this.eForm.survey)
      this.survey.onComplete.add(async function (sender) {
        await self.onCompleteSurvey(sender.data)
      })
      this.keySurvey++
      this.eFormSurveyId = id
    }
  },

  methods: {},
}
</script>

